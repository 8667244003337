import { Injectable } from '@angular/core';
import Nango from '@nangohq/frontend';

import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NangoService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  async authorize(
    nangoIntegrationId: string,
    nangoConfig: any,
    connectionParams?: Array<string>,
    authorizationParams?: Record<string, string>,
  ) {
    const nango = new Nango({ publicKey: environment.nangoPublicKey });
    const options: any = {
      params: nangoConfig ?? {},
      authorization_params: authorizationParams,
    };

    const { hmac, connectionId } = (await this.apiService.postPromise(`/integrations/pre-register`, {
      integration: nangoIntegrationId,
      connectionParams,
    })) as any;

    options.hmac = hmac;

    try {
      await nango.auth(nangoIntegrationId, connectionId, options);
    } catch (err: any) {
      throw new Error(err.message);
    }

    return { connectionId };
  }
}
